import Ionicons from '@expo/vector-icons/Ionicons';
import { Linking, Text, View } from 'react-native-web';
import { useMediaQuery } from 'react-responsive';

import Colors from 'theme/colors';

import styles from './styles';

const LINKS = {
    linkedin: 'https://www.linkedin.com/in/justinjoyn',
    github: 'https://github.com/justinjoyn',
    stackoverflow: 'https://stackoverflow.com/users/4452048/justin',
} as const;

export default function Links() {
    const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 1224 });

    const openLink = (platform: keyof typeof LINKS) => {
        Linking.canOpenURL(LINKS[platform]).then(supported => {
            if (supported) {
                Linking.openURL(LINKS[platform]);
            }
        });
    };

    return (
        <View style={[styles.container, { flexDirection: isDesktopOrLaptop ? 'row' : 'column' }]}>
            <Text style={styles.link} onPress={() => openLink('linkedin')} numberOfLines={1}>
                <Ionicons name="ios-logo-linkedin" size={32} color={Colors.textPrimary} />
                {` LinkedIn`}
            </Text>
            <Text style={styles.link} onPress={() => openLink('github')} numberOfLines={1}>
                <Ionicons name="ios-logo-github" size={32} color={Colors.textPrimary} />
                {` GitHub`}
            </Text>
            <Text style={styles.link} onPress={() => openLink('stackoverflow')} numberOfLines={1}>
                <Ionicons name="ios-logo-stackoverflow" size={32} color={Colors.textPrimary} />
                {` StackOverflow`}
            </Text>
        </View>
    );
}
