import { Image, Text, View } from 'react-native-web';
import { useMediaQuery } from 'react-responsive';

import styles from './styles';

export default function About() {
    const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 1224 });

    const renderGreeting = () => {
        return (
            <Text style={[styles.greeting, styles.bottomPadding]}>
                {`Hi, my name is `}
                <Text style={styles.name} numberOfLines={1}>
                    Justin Joy
                </Text>
                {` and I engineer mobile and web applications.`}
            </Text>
        );
    };

    return (
        <View style={styles.container}>
            <View style={styles.topContainer}>
                <Image source={require('assets/images/me.png')} style={styles.profileImage} />
                {isDesktopOrLaptop && renderGreeting()}
            </View>
            {!isDesktopOrLaptop && renderGreeting()}
        </View>
    );
}
