import { useMemo } from 'react';

import { useFonts } from 'expo-font';
import { View, StyleSheet } from 'react-native-web';
import { useMediaQuery } from 'react-responsive';

import Profile from 'screens/Profile';
import Colors from 'theme/colors';

export default function App() {
    const [fontsLoaded] = useFonts({
        'OpenSans-Light': require('assets/fonts/OpenSans-Light.ttf'),
        'OpenSans-Regular': require('assets/fonts/OpenSans-Regular.ttf'),
        'OpenSans-Bold': require('assets/fonts/OpenSans-Bold.ttf'),
    });

    const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 1224 });

    const containerStyle = useMemo(() => {
        return {
            flex: 1,
            width: isDesktopOrLaptop ? '50%' : '90%',
        };
    }, [isDesktopOrLaptop]);

    if (!fontsLoaded) return null;

    return (
        <View style={styles.page}>
            <View style={containerStyle}>
                <Profile />
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    page: {
        flex: 1,
        backgroundColor: Colors.backgroundPrimary,
        alignItems: 'center',
        paddingVertical: 16,
    },
});
