import { View } from 'react-native-web';

import About from 'components/About';
import Links from 'components/Links';
import Resume from 'components/Resume';

export default function Profile() {
    return (
        <View>
            <About />
            <Links />
        </View>
    );
}
