import { StyleSheet } from 'react-native-web';

import Colors from 'theme/colors';
import Fonts from 'theme/fonts';

const styles = StyleSheet.create({
    container: {
        width: '100%',
        justifyContent: 'center',
        paddingVertical: 100,
    },
    topContainer: {
        flexDirection: 'row',
        borderBottomColor: Colors.textPrimary,
        borderBottomWidth: 1,
        alignItems: 'flex-end',
    },
    bottomPadding: {
        paddingBottom: 16,
    },
    profileImage: {
        width: 300,
        height: 300,
    },
    greeting: {
        fontSize: 42,
        lineHeight: 60,
        fontFamily: Fonts.thin,
        color: Colors.textPrimary,
    },
    name: {
        fontFamily: Fonts.bold,
    },
    intro: {
        marginTop: 50,
        fontFamily: Fonts.thin,
        fontSize: 24,
        lineHeight: 40,
    },
});

export default styles;
