import { StyleSheet } from 'react-native-web';

import Colors from 'theme/colors';
import Fonts from 'theme/fonts';

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    link: {
        fontSize: 24,
        fontFamily: Fonts.thin,
        color: Colors.textPrimary,
        paddingVertical: 8,
    },
});

export default styles;
